import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import PlaceholderImage from '~images/placeholders/resource_card_placeholder.png';
import ResourceMeta from '~components/ResourceMeta';
import Link from '~components/Link';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import { SUBSCRIBE_PATH } from '~utils/subscribe-helpers';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px'
  },
  title: {
    color: '#222222',
    fontSize: 16,
    fontFamily: 'Inter',
    fontWeight: '600',
    marginTop: 0,
    marginBottom: 0,
    maxWidth: '250px'
  },
  description: {
    color: 'black',
    fontSize: 14,
    lineHeight: '21px',
    fontFamily: 'Inter',
    fontWeight: '400',
    marginTop: 0,
    marginBottom: 0
  },
  infoText: {
    color: '#747474',
    fontSize: 12,
    fontFamily: 'Inter',
    fontWeight: '500',
    marginTop: 0,
    marginBottom: 0
  },
  image: {
    maxWidth: '90px',
    borderRadius: 2,
    objectFit: 'contain',
    alignSelf: 'start'
  },
  cardInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4
  }
});

const Resource = ({ custom_title, custom_duration, custom_description, resource }) => {
  const classes = useStyles();
  const { marketingUser } = useUserContext().session;

  const title = custom_title || resource?.title;
  const metaText = custom_duration ? `${resource?.type} • ${custom_duration}` : resource?.metaText;
  const description = custom_description?.text || resource?.description;

  const url =
    resource?.isSubscriberResource && !marketingUser?.isSubscriber ? SUBSCRIBE_PATH : resource?.url;
  const urlTarget =
    resource?.isSubscriberResource && !marketingUser?.isSubscriber ? '_self' : resource?.urlTarget;

  return (
    <Link to={url} target={urlTarget || '_self'} className={classes.root} underline="none">
      <img
        src={resource?.image?.url || PlaceholderImage}
        alt={resource?.image?.alt}
        className={classes.image}
      />
      <div className={classes.cardInfo}>
        <h4 className={classes.title}>{title}</h4>
        <ResourceMeta resourceType={resource?.type} metaText={metaText} size={12} />
        <p className={classes.description}>{description}</p>
      </div>
    </Link>
  );
};

Resource.propTypes = {
  custom_title: PropTypes.string,
  custom_duration: PropTypes.string,
  custom_description: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }),
  resource: PropTypes.shape({
    uid: PropTypes.string,
    url: PropTypes.string,
    urlTarget: PropTypes.string,
    isSubscriberResource: PropTypes.bool,
    title: PropTypes.string,
    type: PropTypes.string,
    metaText: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
      alt: PropTypes.string,
      url: PropTypes.string
    })
  }).isRequired
};

Resource.defaultProps = {
  custom_title: '',
  custom_duration: '',
  custom_description: null
};

export default Resource;
